import { Expose, Type } from 'class-transformer';
import {
  SimApnTypeEnum,
  SimSkinEnum,
  SimStatusEnum,
} from 'common/constants/enum';

import { SimPlanModel } from './plan';
import { SimTransferRequestModel } from './transfer';

/*
Assigned -> Not installed
Installed -> Installed, but not active
Deleted -> only allowed after Deactivated or Archived
*/

export class SimLiteModel {
  id: string;
  label: string;
  skin: SimSkinEnum;
  iccid: string;
  status: SimStatusEnum;
  rechargeable: boolean;

  @Expose({ name: 'archived_at' })
  @Type(() => Date)
  archivedAt: Date | null;
}

export class SimModel {
  id: string;
  label: string;
  iccid: string;
  skin: SimSkinEnum;

  @Expose({ name: 'apn_type' })
  apnType: SimApnTypeEnum;

  @Expose({ name: 'apn_value' })
  apnValue: string;

  @Expose({ name: 'sm_dp_address' })
  smDpAddress: string;

  @Expose({ name: 'activation_code' })
  activationCode: string;

  @Expose({ name: 'manual_code' })
  manualCode: string;

  @Expose({ name: 'confirmation_code' })
  confirmationCode: string;

  rechargeable: boolean;
  status: SimStatusEnum;

  @Expose({ name: 'archived_at' })
  @Type(() => Date)
  archivedAt: Date | null;

  @Expose({ name: 'transfer_request' })
  @Type(() => SimTransferRequestModel)
  transferRequest?: SimTransferRequestModel | null;

  @Expose({ name: 'sim_plans' })
  @Type(() => SimPlanModel)
  simPlans: SimPlanModel[];

  @Expose({ name: 'pending_plans' })
  @Type(() => SimPlanModel)
  pendingPlans: SimPlanModel[] | null;

  @Type(() => SimPlanModel)
  histories: SimPlanModel[] | null;

  @Expose({ name: 'has_remaining_histories' })
  hasRemainingHistories: boolean;
}
