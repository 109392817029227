import { ProductLiteModel } from 'api-hooks/product';
import { Type } from 'class-transformer';
import { SimSkinEnum } from 'common/constants/enum';

import { SimLiteModel } from './sim';

// Recharge
export class SimProductListModel {
  @Type(() => ProductLiteModel)
  locals: ProductLiteModel[];

  @Type(() => ProductLiteModel)
  regionals: ProductLiteModel[];

  @Type(() => SimLiteModel)
  sim: SimLiteModel;
}

export class SimRefreshModel {
  rechargeable: boolean;
}

// Share Link
export class SimShareLinkModel {
  token: string;
}

// Input

export type getSimPlanInput = {
  simId: string;
  simPlanId: string;
};

export type UpdateLabelSimInput = {
  simId: string;
  body: {
    label: string;
  };
};

export type UpdateSkinSimInput = {
  simId: string;
  body: {
    skin: SimSkinEnum;
  };
};

export type SimActivationDateInput = {
  simId: string;
  simPlan: string;
  body: {
    activationAt: Date | null;
  };
};
