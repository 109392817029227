import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { SimModel } from 'api-hooks/sim/model';
import { useGetSim, useGetSims } from 'api-hooks/sim/query';
import { QueryTransformer } from 'common/api/fetch';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';
import { isNetworkError } from 'common/api/utils';
import { LocalStorageKeys } from 'common/constants/browser-storage-keys';
import notification from 'common/helpers/notification';
import React from 'react';

export const ESIM_STORAGE_KEY = LocalStorageKeys.EsimCache;

export function getEsims(): ExtendedApiResult<SimModel[]> | undefined {
  if (typeof window === 'undefined') return;

  const response = localStorage.getItem(ESIM_STORAGE_KEY);
  if (!response) return;

  const result = QueryTransformer({ data: JSON.parse(response) }, SimModel);
  return result.data;
}

export function getEsimStorage(id: string | string[] | undefined) {
  if (typeof window === 'undefined') return;
  const results = getEsims()?.data || [];
  return results?.find((sim) => sim.id === id);
}

function resolveCacheOrActual<T>(
  offlineData: T | null,
  query: UseQueryResult<T, ApiError>,
): UseQueryResult<T, ApiError> {
  if (query.error && isNetworkError(query.error) && offlineData) {
    return {
      ...query,
      data: offlineData,
      error: null,
      isError: false,
      isSuccess: true,
      isLoadingError: false,
      isRefetchError: false,
      status: 'success',
    };
  }

  return {
    ...query,
    isLoading: offlineData ? false : query.isLoading,
    isFetching: offlineData ? false : query.isFetching,
    status: offlineData ? 'success' : query.status,
    data: query.data ?? offlineData ?? undefined,
  } as UseQueryResult<T, ApiError>;
}

function resolveInfiniteCacheOrActual<T>(
  offlineData: T | null,
  query: UseInfiniteQueryResult<T, ApiError>,
): UseInfiniteQueryResult<T, ApiError> {
  if (query.error && isNetworkError(query.error) && offlineData) {
    return {
      ...query,
      data: offlineData
        ? {
            pageParams: [],
            pages: [offlineData],
          }
        : {
            pageParams: [],
            pages: [],
          },
      error: null,
      isError: false,
      isSuccess: true,
      isLoadingError: false,
      isRefetchError: false,
      status: 'success',
    };
  }

  return {
    ...query,
    isLoading: offlineData ? false : query.isLoading,
    isFetching: offlineData ? false : query.isFetching,
    status: offlineData ? 'success' : query.status,
    data:
      query.data ??
      (offlineData
        ? {
            pages: [offlineData],
            pageParams: [],
          }
        : undefined) ??
      undefined,
  } as UseInfiniteQueryResult<T, ApiError>;
}

interface UseGetEsimCachedProps {
  id: string;
  cacheOnly?: boolean;
}

export function useGetEsimCached(
  props: UseGetEsimCachedProps,
): ReturnType<typeof useGetSim> {
  const { id, cacheOnly } = props;
  const query = useGetSim(
    {
      simId: id,
    },
    {
      enabled: !!id && !cacheOnly,
    },
  );

  const offlineData = React.useMemo<ApiResult<SimModel> | null>(() => {
    const data = getEsimStorage(id);
    if (data) {
      return {
        data,
      };
    }
    return null;
  }, [id]);

  React.useEffect(() => {
    if (query.error?.message) {
      notification.error({ message: query.error.message });
    }
  }, [query.error]);

  return resolveCacheOrActual(offlineData, query);
}

interface UseGetEsimsCachedProps {
  cacheOnly?: boolean;
}

export function useGetEsimsCached(props: UseGetEsimsCachedProps) {
  const { cacheOnly } = props;
  const query = useGetSims(
    {
      params: {
        limit: -1,
      },
    },
    {
      enabled: !cacheOnly,
    },
  );
  const offlineData = React.useMemo<ExtendedApiResult<
    SimModel[]
  > | null>(() => {
    const response = localStorage.getItem(ESIM_STORAGE_KEY);
    if (!response) return;

    return QueryTransformer({ data: JSON.parse(response) }, SimModel).data;
  }, []);
  return resolveInfiniteCacheOrActual(offlineData, query);
}
