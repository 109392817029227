import { LocalizedName } from 'api-hooks/common';
import { ProductCoverageGradesModel } from 'api-hooks/product';
import { SubscriptionModel } from 'api-hooks/subscription';
import { Expose, Type } from 'class-transformer';
import {
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
  SimPlanStatusEnum,
} from 'common/constants/enum';

import { SimLiteModel } from './sim';

export class SimPlanModel extends LocalizedName {
  id: string;

  type: DataPlanTypeEnum;

  @Expose({ name: 'quota_limit' })
  @Type(() => Number)
  quotaLimit: number;

  @Expose({ name: 'quota_remaining' })
  @Type(() => Number)
  quotaRemaining: number;

  @Expose({ name: 'validity_days' })
  validityDays: number;

  status: SimPlanStatusEnum;

  @Expose({ name: 'start_at' })
  @Type(() => Date)
  startAt: Date;

  @Expose({ name: 'end_at' })
  @Type(() => Date)
  endAt: Date | null;

  @Expose({ name: 'activation_at' })
  @Type(() => Date)
  activationAt: Date | null;

  @Expose({ name: 'activated_at' })
  @Type(() => Date)
  activatedAt: Date | null;

  @Expose({ name: 'deactivated_at' })
  @Type(() => Date)
  deactivatedAt: Date | null;

  @Expose({ name: 'estimated_expiration' })
  @Type(() => Date)
  estimatedExpiration: Date | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'product_id' })
  productId: string;

  @Expose({ name: 'product_item_id' })
  productItemId: string;

  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'fx_rate' })
  fxRate: string;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'sale_invoice_id' })
  saleInvoiceId?: string;

  @Expose({ name: 'new_sim' })
  newSim?: boolean;

  @Type(() => SubscriptionModel)
  subscription: SubscriptionModel | null;
}

// Specific to Sim Plan page
export class SimPlanPageProductModel {
  description: string;
  slug: string;

  @Expose({ name: 'can_recharge' })
  canRecharge: boolean;
  details: {
    text: string;
    value: boolean;
  }[];

  @Type(() => ProductCoverageGradesModel)
  coverages: ProductCoverageGradesModel;
}

export class SimPlanPageModel {
  @Type(() => SimLiteModel)
  sim: SimLiteModel;

  @Type(() => SimPlanModel)
  plan: SimPlanModel;

  @Type(() => SimPlanPageProductModel)
  product: SimPlanPageProductModel;

  @Expose({ name: 'can_subscribe' })
  canSubscribe: boolean;

  @Expose({ name: 'has_subscription' })
  hasSubscription: boolean;
}
