import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { IdInput, StaleTimes, getParamsInput } from 'api-hooks/common';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import {
  ApiQueryFunction,
  InfiniteApiQueryFunction,
} from 'common/api/fetch-types';
import { ApiResult, ExtendedApiResult } from 'common/api/model';
import { maybeElement } from 'common/utils/iterable';

import {
  SubscriptionLiteModel,
  SubscriptionModel,
  SubscriptionPreprocessDetailsModel,
} from './model';

export const subscriptionKeys = {
  listKey: 'getSubscriptions',
  detailKey: 'getSubscription',
  preprocessDetailsKey: 'getPreprocessDetails',
  list(input?: getParamsInput) {
    return [subscriptionKeys.listKey, ...maybeElement(!!input, input?.params)];
  },
  detail(input?: IdInput) {
    return [subscriptionKeys.detailKey, ...maybeElement(!!input, input?.id)];
  },
  // This receives the sim plan ID
  preprocessDetails(input?: IdInput) {
    return [
      subscriptionKeys.preprocessDetailsKey,
      ...maybeElement(!!input, input?.id),
    ];
  },
};
const ENDPOINT = 'subscriptions';
export const useGetSubscriptions: InfiniteApiQueryFunction<
  getParamsInput,
  ExtendedApiResult<SubscriptionLiteModel[]>
> = (input, options) => {
  return QueryTransformer(
    useInfiniteQuery(
      subscriptionKeys.list(input),
      ({ pageParam = 1 }) => {
        return QueryFetchFunction({
          url: ENDPOINT,
          params: {
            ...input?.params,
            page: pageParam,
          },
        });
      },
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    SubscriptionLiteModel,
  );
};

/** @deprecated */
export const useGetSubscription: ApiQueryFunction<
  IdInput,
  ApiResult<SubscriptionModel>
> = (input, options) => {
  return QueryTransformer(
    useQuery(
      subscriptionKeys.detail(input),
      () => {
        return QueryFetchFunction({
          url: `${ENDPOINT}/${input.id}`,
        });
      },
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    SubscriptionModel,
  );
};

/** Gets payment information using a sim plan ID */
export const useGetSubscriptionPreprocessDetails: ApiQueryFunction<
  IdInput,
  ApiResult<SubscriptionPreprocessDetailsModel>
> = (input, options) => {
  return QueryTransformer(
    useQuery(
      subscriptionKeys.preprocessDetails(input),
      () => {
        return QueryFetchFunction({
          url: `${ENDPOINT}/${input.id}/preprocess-details`,
          params: {
            sim_plan_id: input.id,
          },
        });
      },
      options,
    ),
    SubscriptionPreprocessDetailsModel,
  );
};
