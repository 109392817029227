import { getParamsInput } from 'api-hooks/common';
import { Expose } from 'class-transformer';
import { SimStatusEnum, SimApnTypeEnum } from 'common/constants/enum';
export * from './actions';
export * from './plan';
export * from './sim';
export * from './transfer';

/** @deprecated */
export class CredentialModel {
  id: string;
  label: string;
  iccid: string;
  status: SimStatusEnum;

  @Expose({ name: 'sm_dp_address' })
  smDpAddress: string;

  @Expose({ name: 'activation_code' })
  activationCode: string;

  @Expose({ name: 'confirmation_code' })
  confirmationCode: string | null;

  @Expose({ name: 'apn_type' })
  apnType: SimApnTypeEnum;

  @Expose({ name: 'apn_value' })
  apnValue: string;
}

// Input
export type getSimsInput = getParamsInput<{
  filter?: {
    rechargeable?: 0 | 1;
  };
}>;

export type getRechargeableSimsInput = getParamsInput & {
  productId: string;
};

export type getSimInput = {
  simId: string;
};
export type getPublicSimInput = {
  simId: string;
  token: string;
};

export type getSimProductListInput = {
  simId: string;
  params?: {
    q?: string;
  };
};

export type getSimIccid = {
  iccid: string;
};

export type SimIdInput = {
  simId: string;
};
