// Model

import { CommonUpdateInput, LocalizedName } from 'api-hooks/common';
import { CreditDebitCardModel } from 'api-hooks/payment-method';
import { ProductItemModel } from 'api-hooks/product';
import { SaleInvoiceAvailablePaymentMethodModel } from 'api-hooks/sale-invoice';
import { SimPlanModel } from 'api-hooks/sim/model';
import { Expose, Type } from 'class-transformer';
import {
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
  SubscriptionStatusEnum,
  UnsubscribeReasonEnum,
} from 'common/constants/enum';

export class SubscriptionLiteModel {
  id: string;
  status: SubscriptionStatusEnum;

  @Expose({ name: 'subscribed_at' })
  @Type(() => Date)
  subscribedAt: Date;

  @Expose({ name: 'unsubscribed_at' })
  @Type(() => Date)
  unsubscribedAt: Date | null;

  @Expose({ name: 'unsubscribe_reason' })
  unsubscribeReason: UnsubscribeReasonEnum | null;

  @Expose({ name: 'renew_at' })
  @Type(() => Date)
  renewAt: Date;

  @Expose({ name: 'low_data_recharge' })
  lowDataRecharge: boolean;

  sim?: {
    id: string;
    iccid: string;
    label: string;
  };

  @Expose({ name: 'sim_plan' })
  @Type(() => SimPlanModel)
  simPlan?: SimPlanModel;
}

export class SubscriptionModel extends SubscriptionLiteModel {
  @Expose({ name: 'product_item' })
  @Type(() => ProductItemModel)
  productItem: ProductItemModel;

  @Type(() => CreditDebitCardModel)
  payment: CreditDebitCardModel;
}

// not sim plan model nor product item model
export class SubscriptionPreprocessDetailsPlanProductModel extends LocalizedName {
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'is_available' })
  isAvailable: boolean;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Expose({ name: 'discount_percentage' })
  @Type(() => Number)
  discountPercentage: number;

  @Expose({ name: 'discounted_price' })
  @Type(() => Number)
  discountedPrice: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'data_type' })
  dataType: DataPlanTypeEnum;

  @Expose({ name: 'quota_in_gb' })
  quotaInGb: number;
}

export class SubscriptionPreprocessDetailsModel {
  @Expose({ name: 'plan_product' })
  @Type(() => SubscriptionPreprocessDetailsPlanProductModel)
  planProduct: SubscriptionPreprocessDetailsPlanProductModel;

  @Expose({ name: 'payment_methods' })
  @Type(() => SaleInvoiceAvailablePaymentMethodModel)
  paymentMethods: SaleInvoiceAvailablePaymentMethodModel[];

  sim: {
    id: string;
    iccid: string;
    label: string;
  };
}

// Input

export type SubscriptionMutationInput = {
  lowDataRecharge: boolean;
  simPlanId: string;
  creditDebitCardId: string;
};
export type SubscriptionUpdateInput = CommonUpdateInput<{
  lowDataRecharge: boolean;
}>;

export type SubscriptionMassDeleteInput = {
  subscriptionsId: string[];
};
