import {
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { IdInput, StaleTimes, getParamsInput } from 'api-hooks/common';
import { ProductCoverageModel } from 'api-hooks/product';
import { QueryFetchFunction, QueryTransformer } from 'common/api/fetch';
import {
  ApiQueryFunction,
  InfiniteApiQueryFunction,
} from 'common/api/fetch-types';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';
import { maybeElement as mbel } from 'common/utils/iterable';
import { ESIM_STORAGE_KEY } from 'modules/esim/hooks/use-get-esim-cached';

import {
  getSimIccid,
  getSimInput,
  getSimProductListInput,
  CredentialModel,
  SimModel,
  SimProductListModel,
  getPublicSimInput,
  getSimPlanInput,
  SimPlanModel,
  getSimsInput,
  getRechargeableSimsInput,
  SimPlanPageModel,
} from './model';

export const simKey = {
  listKey: 'getSims',
  productRechargeableListKey: 'getProductRechargeableSims',
  detailKey: 'getSim',
  planKey: 'getSimPlan',
  publicDetailKey: 'getPublicSim',
  credentialKey: 'getSimCredential',
  publicCredentialKey: 'getPublicSimCredential',
  historyKey: 'getSimHistory',
  productKey: 'getSimProduct',
  publicKey: 'getSimPublic',
  iccidKey: 'getSimIccid',
  pendingKey: 'getSimPending',
  simPlanCoverage: 'getSimPlanCoverage',
  subscribedKey: 'getSimSubscribedKey',
  subscribableKey: 'getSimSubscribableKey',
  list(input?: getParamsInput) {
    return [simKey.listKey, ...mbel(!!input, input)];
  },
  productRechargeableList(input?: getRechargeableSimsInput) {
    return [
      simKey.productRechargeableListKey,
      ...mbel(!!input, [input?.productId, input?.params]),
    ];
  },
  detail(input?: getSimInput) {
    return [simKey.detailKey, ...mbel(!!input, input?.simId)];
  },
  credential(input?: getSimInput) {
    return [simKey.credentialKey, ...mbel(!!input, input?.simId)];
  },
  publicDetail(input?: getPublicSimInput) {
    return [
      simKey.publicDetailKey,
      ...mbel(!!input, [input?.simId, input?.token]),
    ];
  },
  publicCredential(input?: getPublicSimInput) {
    return [
      simKey.publicCredentialKey,
      ...mbel(!!input, [input?.simId, input?.token]),
    ];
  },
  history(input?: IdInput) {
    return [simKey.historyKey, ...mbel(!!input, input?.id)];
  },
  product(input?: getSimProductListInput) {
    return [simKey.productKey, ...mbel(!!input, input?.params)];
  },
  iccid(input?: getSimIccid) {
    return [simKey.iccid, ...mbel(!!input, input?.iccid)];
  },
  pending(input?: IdInput) {
    return [simKey.pendingKey, ...mbel(!!input, input?.id)];
  },
  subscribed(input?: IdInput) {
    return [simKey.subscribedKey, ...mbel(!!input, input?.id)];
  },
  subscribable(input?: IdInput) {
    return [simKey.subscribableKey, ...mbel(!!input, input?.id)];
  },
  plan(input?: getSimPlanInput) {
    return [simKey.planKey, ...mbel(!!input, [input?.simId, input?.simPlanId])];
  },
  planCoverage(input?: getSimPlanInput) {
    return [
      simKey.simPlanCoverage,
      ...mbel(!!input, input?.simId, input?.simPlanId),
    ];
  },
};

export function invalidateEsimData(id: string) {
  queryClient.invalidateQueries(simKey.list());
  queryClient.invalidateQueries(simKey.productRechargeableList());
  queryClient.invalidateQueries(simKey.detail({ simId: id }));
  queryClient.invalidateQueries(simKey.credential({ simId: id }));
  queryClient.invalidateQueries(simKey.history({ id }));
  queryClient.invalidateQueries(simKey.pending({ id }));
  queryClient.invalidateQueries(simKey.subscribable({ id }));
  queryClient.invalidateQueries([simKey.planKey, id]);
}

const ENDPOINT = 'sims';
const PUBLIC_ENDPOINT = 'public/sims';
export const useGetSims: InfiniteApiQueryFunction<
  getSimsInput | undefined,
  ExtendedApiResult<SimModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.list(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: ENDPOINT,
          params: {
            limit: 15,
            page: pageParam,
            ...input?.params,
          },
          cache: ESIM_STORAGE_KEY,
          // cache: true,
        }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    SimModel,
  );
};

export const useGetProductRechargeableSims: InfiniteApiQueryFunction<
  getRechargeableSimsInput,
  ExtendedApiResult<SimModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.productRechargeableList(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `products/${input.productId}/rechargeable-sims`,
          params: {
            limit: 15,
            page: pageParam,
            ...input?.params,
          },
        }),
      {
        staleTime: StaleTimes.Long,
        cacheTime: StaleTimes.Long * 2,
        ...options,
      },
    ),
    SimModel,
  );
};

export const useGetSim: ApiQueryFunction<
  getSimInput,
  ApiResult<SimModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      simKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.simId}`,
        }),
      {
        staleTime: StaleTimes.Medium,
        cacheTime: StaleTimes.Medium * 2,
        ...options,
      },
    ),
    SimModel,
  );
};

export function useGetSimPlan(
  input: getSimPlanInput,
  options?: UseQueryOptions<ApiResult<SimPlanPageModel>, ApiError>,
): UseQueryResult<ApiResult<SimPlanPageModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      simKey.plan(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.simId}/sim-plans/${input.simPlanId}`,
        }),
      options,
    ),
    SimPlanPageModel,
  );
}

/** @deprecated */
export const useGetSimCredential: ApiQueryFunction<
  getSimInput,
  ApiResult<CredentialModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      simKey.credential(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.simId}/credential`,
        }),
      options,
    ),
    CredentialModel,
  );
};

export const useGetPublicSim: ApiQueryFunction<
  getPublicSimInput,
  ApiResult<SimModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      simKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `${PUBLIC_ENDPOINT}/${input.simId}`,
          params: {
            token: input.token,
          },
        }),
      options,
    ),
    SimModel,
  );
};

/** @deprecated */
export const useGetPublicSimCredential: ApiQueryFunction<
  getPublicSimInput,
  ApiResult<CredentialModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      simKey.credential(input),
      () =>
        QueryFetchFunction({
          url: `${PUBLIC_ENDPOINT}/${input.simId}/credential`,
          params: {
            token: input.token,
          },
        }),
      options,
    ),
    CredentialModel,
  );
};

export const useGetSimHistory: InfiniteApiQueryFunction<
  IdInput,
  ExtendedApiResult<SimPlanModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.history(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.id}/history`,
          params: {
            page: pageParam,
            limit: 15,
          },
        }),
      options,
    ),
    SimPlanModel,
  );
};

/** @deprecated */
export const useGetSimPending: InfiniteApiQueryFunction<
  IdInput,
  ExtendedApiResult<SimPlanModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.pending(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.id}/pending`,
          params: {
            page: pageParam,
            limit: 15,
          },
        }),
      options,
    ),
    SimPlanModel,
  );
};

/** @deprecated */
export const useGetSimSubscribedPlans: InfiniteApiQueryFunction<
  IdInput,
  ExtendedApiResult<SimPlanModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.subscribed(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.id}/subscribed-plans`,
          params: {
            page: pageParam,
            limit: 15,
          },
        }),
      options,
    ),
    SimPlanModel,
  );
};

/** @deprecated */
export const useGetSimSubscribablePlans: InfiniteApiQueryFunction<
  IdInput,
  ExtendedApiResult<SimPlanModel[]>
> = function (input, options) {
  return QueryTransformer(
    useInfiniteQuery(
      simKey.subscribable(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.id}/subscribable-plans`,
          params: {
            page: pageParam,
            limit: 15,
          },
        }),
      options,
    ),
    SimPlanModel,
  );
};

export function useGetSimProductList(
  input?: getSimProductListInput,
  options?: UseQueryOptions<ApiResult<SimProductListModel>, ApiError>,
): UseQueryResult<ApiResult<SimProductListModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      simKey.product(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.simId}/product-list`,
          params: input?.params,
        }),
      options,
    ),
    SimProductListModel,
  );
}

export function useGetSimIccid(
  input?: getSimIccid,
  options?: UseQueryOptions<ApiResult<SimModel>, ApiError>,
): UseQueryResult<ApiResult<SimModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      simKey.iccid(input),
      () => QueryFetchFunction({ url: `${ENDPOINT}/iccid/${input?.iccid}` }),
      options,
    ),
    SimModel,
  );
}

export function useGetSimPlanCoverage(
  input?: getSimPlanInput,
  options?: UseQueryOptions<ApiResult<ProductCoverageModel[]>, ApiError>,
): UseQueryResult<ApiResult<ProductCoverageModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      simKey.planCoverage(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input?.simId}/sim-plans/${input?.simPlanId}/coverages`,
        }),
      options,
    ),
    ProductCoverageModel,
  );
}
