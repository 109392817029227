import { useMutation } from '@tanstack/react-query';
import { IdInput } from 'api-hooks/common';
import { simKey } from 'api-hooks/sim/query';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiMutationFunction } from 'common/api/fetch-types';
import { ApiResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import {
  SubscriptionMassDeleteInput,
  SubscriptionModel,
  SubscriptionMutationInput,
  SubscriptionUpdateInput,
} from './model';
import { subscriptionKeys } from './query';

const ENDPOINT = 'subscriptions';
export const useCreateSubscription: ApiMutationFunction<
  SubscriptionMutationInput,
  ApiResult<SubscriptionModel>
> = (options) => {
  return useMutation(
    (body) => {
      return MutationFetchFunction({
        url: ENDPOINT,
        method: 'POST',
        body,
        classType: SubscriptionModel,
      });
    },
    {
      onSuccess(data: ApiResult<SubscriptionModel>, variables, context) {
        queryClient.invalidateQueries(subscriptionKeys.list());
        if (data.data.sim) {
          queryClient.invalidateQueries(
            simKey.detail({
              simId: data.data.sim.id,
            }),
          );
          queryClient.invalidateQueries(
            simKey.plan({
              simId: data.data.sim.id,
              simPlanId: variables.simPlanId,
            }),
          );
        }
      },
      ...options,
    },
  );
};

export const useUpdateSubscription: ApiMutationFunction<
  SubscriptionUpdateInput,
  ApiResult<SubscriptionModel>
> = (options) => {
  return useMutation(
    (body) => {
      return MutationFetchFunction({
        url: `${ENDPOINT}/${body.id}`,
        method: 'PUT',
        body: body.body,
        classType: SubscriptionModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(
          subscriptionKeys.detail({
            id: variables.id,
          }),
        );
      },
      ...options,
    },
  );
};

export const useDeleteSubscription: ApiMutationFunction<
  IdInput,
  ApiResult<never>
> = (options) => {
  return useMutation(
    (body) => {
      return MutationFetchFunction({
        url: `${ENDPOINT}/${body.id}/unsubscribe`,
        method: 'POST',
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(subscriptionKeys.list());
        queryClient.invalidateQueries(simKey.list());
        queryClient.invalidateQueries(
          subscriptionKeys.detail({
            id: variables.id,
          }),
        );
      },
      ...options,
    },
  );
};

export const useMassDeleteSubscription: ApiMutationFunction<
  SubscriptionMassDeleteInput,
  ApiResult<never>
> = (options) => {
  return useMutation(
    (body) => {
      return MutationFetchFunction({
        url: `${ENDPOINT}`,
        method: 'DELETE',
        body,
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(subscriptionKeys.list());
        for (const id of variables.subscriptionsId) {
          queryClient.invalidateQueries(
            subscriptionKeys.detail({
              id,
            }),
          );
        }
      },
      ...options,
    },
  );
};
