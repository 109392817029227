import { UserLiteModel } from 'api-hooks/auth';
import { Type, Expose } from 'class-transformer';
import { SimTransferRequestStatusEnum } from 'common/constants/enum';

export class SimTransferRequestModel {
  id: string;
  status: SimTransferRequestStatusEnum;

  @Type(() => UserLiteModel)
  user: UserLiteModel;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  requestedAt: Date;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  acceptedAt: Date | null;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  rejectedAt: Date | null;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  voidAt: Date | null;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export type SimTransferRequestInput = {
  simId: string;
  simTransferRequestId: string;
};

export type TransferSimInput = {
  simId: string;
  body: {
    email: string;
  };
};
